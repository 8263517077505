import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { HashLink as Link } from "react-router-hash-link";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Navigation.css";
import "../../../App.css";

import px from "../../../img/px.svg";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";

import BtnToggle from "../Buttons/BtnToggles";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";

const Navigation = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Navbar
      expand="lg"
      sticky="top"
      className={` border-bottom  border-3 border-warning ${
        theme ? `bg-darkness ` : `bg-light`
      }`}
    >
      <Container fluid className="mulish mulish-weight">
        <Link to="/#home" aria-current="page">
          <Navbar.Brand href="#">
            <img
              src={px}
              className={`App-nav ${
                theme ? `imagedefond-dark` : `imagedefond-light`
              }`}
              alt="logo"
            />
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle aria-controls="navbarScroll" />

        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            {/* Bouton Accueil */}
            <Button
              variant="transparent"
              className="me-3 border border-warning"
            >
              <Link
                className={`nav-style ${
                  theme ? `nav-style-light` : `nav-style-dark`
                }`}
                to="/#home"
              >
                Accueil
              </Link>
            </Button>

            {/* Bouton Actualités */}
            <Dropdown as={ButtonGroup} className="me-3 border border-warning">
              <Button variant="transparent">
                <Link
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                  to="/news/#banner"
                >
                  Actualités
                </Link>
              </Button>

              <Dropdown.Toggle
                split
                variant="warning"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu
                className={`nav-style ${
                  theme ? `nav-style-light` : `nav-style-dark`
                }`}
                // className={theme ? `nav-style-light` : `nav-style-dark`}
              >
                <Dropdown.Item
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                  // className={theme ? `nav-style-light` : `nav-style-dark`}
                  id="prog"
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/news/#eventschedule"
                  >
                    Programmation évènementielle
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />

                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/news/#talkabout"
                  >
                    Ils parlent de nous
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Bouton A propos */}
            <Dropdown as={ButtonGroup} className="me-3 border border-warning">
              <Button variant="transparent">
                <Link
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                  to="/about/#banner"
                >
                  &Agrave; propos
                </Link>
              </Button>

              <Dropdown.Toggle
                split
                variant="warning"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu
                className={`nav-style ${
                  theme ? `nav-style-light` : `nav-style-dark`
                }`}
              >
                <Dropdown.Item
                  id="prog"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/about/#national-experimention"
                  >
                    L'expérimentation nationale
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />

                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  {/* <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/news/#talkabout"
                  >
                    Déclic et des Claps{" "}
                  </Link> */}

                  <NavDropdown
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    drop="end"
                    title="Déclic et des Claps "
                    navbarScroll
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item
                      //  className={`nav-style ${
                      //     theme ? `nav-style-light` : `nav-style-dark`
                      //   }`}
                      className="nav-style"
                      href="#decl1"
                    >
                      Titre à définir
                    </NavDropdown.Item>
                    <NavDropdown.Divider
                      className={`nav-style ${
                        theme ? `nav-style-light` : `nav-style-dark`
                      }`}
                    />
                    <NavDropdown.Item
                      // className={`nav-style ${
                      //   theme ? `nav-style-light` : `nav-style-dark`
                      // }`}
                      className="nav-style"
                      href="#adecl2"
                    >
                      Raison d'être et valeurs
                    </NavDropdown.Item>
                    <NavDropdown.Divider
                      className={`nav-style ${
                        theme ? `nav-style-light` : `nav-style-dark`
                      }`}
                    />
                    <NavDropdown.Item
                      // className={`nav-style ${
                      //   theme ? `nav-style-light` : `nav-style-dark`
                      // }`}
                      className="nav-style"
                      href="#decl3"
                    >
                      Photo et témoignage des salariés
                    </NavDropdown.Item>
                  </NavDropdown>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Bouton Nos Services */}
            <Dropdown as={ButtonGroup} className="me-3 border border-warning">
              <Button variant="transparent">
                <Link
                  className={`
                    nav-style ${theme ? `nav-style-light` : `nav-style-dark`}`}
                  to="/services/#banner"
                >
                  Nos services
                </Link>
              </Button>

              <Dropdown.Toggle
                split
                variant="warning"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu
                className={`nav-style ${
                  theme ? `nav-style-light` : `nav-style-dark`
                }`}
              >
                <Dropdown.Item
                  id="prog"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/services/#lol"
                  >
                    LOL{" "}
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />

                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/services/#games"
                  >
                    Les animations jeux
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />

                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/services/#guinguette-mobile"
                  >
                    La guinguette mobile
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />

                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/services/#bienvenue"
                  >
                    Bienvenue ! Conciergerie
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />
                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/services/#loc-vaisselle"
                  >
                    Location de vaisselle
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />

                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/services/#supports"
                  >
                    Le soutien aux associations et collectivités
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />
                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/services/#testimony"
                  >
                    Témoignage des clients
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />
                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/services/#perisco"
                  >
                    Ateliers périscolaires
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Bouton Rejoignez-nous */}
            <Dropdown as={ButtonGroup} className="me-3 border border-warning">
              <Button variant="transparent">
                <Link
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                  to="/join-us/#banner"
                >
                  Rejoignez-nous
                </Link>
              </Button>

              <Dropdown.Toggle
                split
                variant="warning"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu
                className={`nav-style ${
                  theme ? `nav-style-light` : `nav-style-dark`
                }`}
              >
                <Dropdown.Item
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                  id="prog"
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/join-us/#partners"
                  >
                    Partenaires
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />

                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/join-us/#volunteers"
                  >
                    Bénévoles
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />

                <Dropdown.Item
                  href="#actu2"
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/join-us/#unemployed"
                  >
                    Demandeurs d'emploi
                  </Link>
                </Dropdown.Item>

                <NavDropdown.Divider
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                />

                <Dropdown.Item
                  className={`nav-style ${
                    theme ? `nav-style-light` : `nav-style-dark`
                  }`}
                  href="#actu2"
                >
                  <Link
                    className={`nav-style ${
                      theme ? `nav-style-light` : `nav-style-dark`
                    }`}
                    to="/join-us/#donation"
                  >
                    Faire un don
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Bouton contacts */}
            <Button
              variant="transparent"
              className="me-2 border border-warning"
            >
              <Link
                className={`nav-style ${
                  theme ? `nav-style-light` : `nav-style-dark`
                }`}
                to="/contact/#banner"
              >
                Contacts
              </Link>
            </Button>

            {/*  <Nav.Link href="#" disabled>
                  Link
                </Nav.Link> */}
            <Nav.Link href="#action1">
              <BtnToggle />
            </Nav.Link>
          </Nav>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Recherche…"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-warning">Recherche</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
