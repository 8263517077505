import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import "../../../App.css";
import "./Footer.css";
import px from "../../../img/px.svg";
import { BiLogoFacebookSquare } from "react-icons/bi";
import { FaSquareInstagram } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <div
        className={`border-top  border-3 border-warning fixed-bottom px-2 pt-xs-2 pt-sm-2 foot-link ${
          theme ? `bg-darkness` : `bg-light`
        }`}
      >
        <h6>
          <Link className="nav-style" to="/#home">
            <span href="#">
              <img
                src={px}
                className={`App-logo-foot ${
                  theme ? `imagedefond-dark` : `imagedefond-light`
                }`}
                alt="logo"
              />{" "}
            </span>
          </Link>

          <span className={theme ? `foot-style-light` : `foot-style-dark`}>
            {" "}
            <span className="Texte1">© Déclic et des Claps 2024</span>
          </span>
          <span className={theme ? `foot-style-light` : `foot-style-dark`}>
            {" "}
            <span className="Texte1">- Tous droits réservés </span>
          </span>

          <a
            className={theme ? `foot-style-light` : `foot-style-dark`}
            href="https://www.instagram.com/declicetdesclapsleteil/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FaSquareInstagram className="mb-1" />
            </span>
            <span>Instagram </span>
            <span className="foot-style-light"> I </span>
          </a>

          <a
            className={theme ? `foot-style-light` : `foot-style-dark`}
            href="https://www.facebook.com/profile.php?id=100094026464760"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <BiLogoFacebookSquare className="mb-1" />
            </span>
            <span>Facebook </span>
          </a>

          <span className={theme ? `foot-style-light` : `foot-style-dark`}>
            I{" "}
          </span>
          <span className={theme ? `foot-style-light` : `foot-style-dark`}>
            S'abonner à la newsletter
          </span>
          <span className={theme ? `foot-style-light` : `foot-style-dark`}>
            {" "}
            I{" "}
          </span>

          <Link
            className={theme ? `foot-style-light` : `foot-style-dark`}
            to="/contact/#banner"
          >
            Contacts
          </Link>

          <Link
            className={theme ? `foot-style-light` : `foot-style-dark`}
            to="/infos#"
            aria-current="page"
          >
            <span className="Texte1">&nbsp;Informations légales</span>
          </Link>
        </h6>
      </div>
    </>
  );
};

export default Footer;
