import Donate from "./Donate";
import Ourpartners from "./Ourpartners";

import Unemployed from "./Unemployed";
import Volunteers from "./Volunteers";
import join from "../../../img/join.svg";
import "../../../App.css";

const Join = () => {
  return (
    <>
      <div id="banner"> </div>

      <div>
        <img className="Banner" src={join} alt="rejoignez-nous" />
        <h1 className="text-over">
          En Savoir + <br />
          <p>&#8964;</p>
        </h1>
      </div>
      <Ourpartners />
      <Volunteers />
      <Unemployed />
      <Donate />
    </>
  );
};

export default Join;
