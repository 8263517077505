import EventSchedule from "./EventSchedule";
import TalkAbout from "./TalkAbout";
import news from "../../../img/news.svg";
import "../../../App.css";

/* import H2 from "../Elements/H2"; */

const News = () => {
  return (
    <>
      <span id="banner"> </span>
      <div>
        <img className="Banner" src={news} alt="news" />
        <h1 className="text-over">
          En Savoir + <br />
          <p>&#8964;</p>
        </h1>
      </div>

      <EventSchedule />
      <TalkAbout />
    </>
  );
};

export default News;
