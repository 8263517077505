import AboutExperiment from "./AboutExperiment";
import team from "../../../img/team-01.jpg";
import "../../../App.css";

const About = () => {
  return (
    <>
      <span id="banner"></span>
      <div>
        <img className="Banner" id="about-experiment" src={team} alt="team" />
        <h1 className="text-over">
          En Savoir + <br />
          <p>&#8964;</p>
        </h1>
      </div>

      <AboutExperiment />
    </>
  );
};

export default About;
